import React from "react";
import { pageTitle } from "../../helpers/PageTitle";
import Section from "../Section";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import FeaturesSectionStyle2 from "../Section/FeaturesSection/FeaturesSectionStyle2";

const featureListData = [
  {
    title: "Integrity",
    subTitle: "",
    iconUrl: "images/icons/professional.svg",
  },
  {
    title: "Collaboration",
    subTitle: "",
    iconUrl: "images/icons/comprehensive.svg",
  },
  {
    title: "Innovation",
    subTitle: "",
    iconUrl: "images/icons/patient.svg",
  },
  {
    title: "Speed",
    subTitle: "",
    iconUrl: "images/icons/facilities.svg",
  },
];

export default function About() {
  pageTitle("About");
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/about_banner_bg_1.svg"
        imgUrl="/images/about/about_us.jpg"
        title="About Us"
        subTitle="At EnquAIre, we are pioneers in leveraging the power of Generative AI to redefine healthcare interactions. As a startup co-founded by experienced academics and industry experts, our mission is to conveniently bridge communication gaps in patient care with the power of modern generative AI, enhancing both quality and efficiency. Our team brings together extensive experience from the healthcare sector, academia, and the industry, allowing us to strategically innovate at the intersection of technology and medical care. Our flagship product transforms patient check-in processes by providing doctors with precise, customized patient information, thereby empowering them to deliver more informed and efficient care. We are committed to pushing the boundaries of healthcare technology, with a pipeline of solutions designed to tackle critical healthcare challenges. Join us as we shape the future of healthcare with cutting-edge AI solutions."
      />
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Our Values"
          imgUrl="images/about/values_1.jpg"
          data={featureListData}
        />
      </Section>
    </>
  );
}
