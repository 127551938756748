import { OverlayTrigger, Popover } from "react-bootstrap";
import "./styles.scss";

const CustomPopover = ({ dataContent, triggerType = "hover", children }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <strong className="cs_accent_color">{dataContent}</strong>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={triggerType} placement="bottom" overlay={popover}>
      <div className="transparent_btn disable_btn">
        <button className="disable_click" variant="primary">
          {children}
        </button>
      </div>
    </OverlayTrigger>
  );
};

export default CustomPopover;
