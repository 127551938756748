import React from "react";
import { pageTitle } from "../../helpers/PageTitle";
import Benefits from "../Benefits";
import Hero from "../Hero";
import Section from "../Section";
import BrandsSection from "../Section/BrandsSection";

const brandData = [
  {
    imgUrl: "images/partners/raleigh_endocrine_associates_logo.png",
    imgAlt: "Logo of Raleigh Endocrine Associates",
  },
  {
    imgUrl: "images/partners/st_luke_hospital_logo.png",
    imgAlt: "Logo of St. Luke's Hospital",
  },
  { imgUrl: "images/partners/nsf_logo.png", imgAlt: "Logo of NSF" },
  {
    imgUrl: "images/partners/nc_state_logo.png",
    imgAlt: "Logo of North Carolina State University",
  },
  {
    imgUrl: "images/partners/unc_chapel_hill_logo.png",
    imgAlt: "Logo of University of North Carolina at Chapel Hill",
  },
  {
    imgUrl: "images/partners/michigan_state_university_logo.png",
    imgAlt: "Logo of Michigan State University",
  },
  {
    imgUrl: "images/partners/microsoft_logo.png",
    imgAlt: "Logo of Microsoft",
  },
  {
    imgUrl: "images/partners/cisco_logo.png",
    imgAlt: "Logo of Cisco",
  },
  {
    imgUrl: "images/partners/sony_logo.png",
    imgAlt: "Logo of Sony",
  },
  {
    imgUrl: "images/partners/3m_logo.png",
    imgAlt: "Logo of 3M",
  },
  {
    imgUrl: "images/partners/solventum_logo.png",
    imgAlt: "Logo of Solventum",
  },
  {
    imgUrl: "images/partners/technicolor_logo.png",
    imgAlt: "Logo of Technicolor",
  },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="Healthcare Reimagined!"
        subTitle="Redesigning patient care workflow in healthcare"
        subTitle2={
          <>
            <span className="cs_f_st_italics">Powered by{"   "}</span>
            <strong class="cs_fs_32 cs_accent_color">Generative AI</strong>.
          </>
        }
        bgUrl="/images/home_1/home_bg.jpg"
        imgUrl="/images/home_1/hero_img_4.jpg"
        videoBtnText="See how it works"
        videoUrl="/images/home_1/demo-video.mp4"
        infoList={[
          {
            title: "Generative AI Powered",
            iconUrl: "/images/contact/icon_1.png",
          },
          {
            title: "Naturally Interactive",
            iconUrl: "/images/contact/icon_2.png",
          },
          {
            title: "Smart Medical Assistant",
            iconUrl: "/images/contact/icon_3.png",
          },
        ]}
        btnText="Request a Demo"
        btnUrl="/appointments"
      />

      <Benefits />
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection
          sectionTitle="Bringing experience from"
          data={brandData}
        />
      </Section>

      {/* Start Feature Section */}
      {/* <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section> */}
      {/* End Feature Section */}
      {/* Start About Section */}

      {/* <Section className="home-about-section">
        <AboutSection
          imgUrl="/images/home_1/about.png"
          // spiningImgUrl="/images/home_1/about_mini.svg"
          title="Saving Doctor's Time"
          subTitle="EnquAIre"
          featureList={[
            {
              featureListTitle: "Lorem ipsum dolor sit amet",
              featureListSubTitle:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae aliquam nulla. Nulla in ullamcorper eros. Aenean sit amet malesuada ligula. In hac habitasse platea dictumst. Suspendisse porta tempus turpis eget dapibus. Quisque pretium varius metus sit amet tincidunt. Donec nec lacus suscipit, bibendum augue auctor, pulvinar quam.",
            },
          ]}
        />
        <AboutSection
          imgUrl="/images/home_1/about_1.png"
          // spiningImgUrl="/images/home_1/about_mini.svg"
          title="Empowering doctors for accurate diagnosis"
          subTitle="EnquAIre"
          featureList={[
            {
              featureListTitle:
                "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet",
              featureListSubTitle:
                "Maecenas id ex in libero semper pretium. In scelerisque vulputate metus, sit amet commodo neque tincidunt ut. Duis sit amet diam massa. Fusce tincidunt nisl non hendrerit cursus. Pellentesque et erat sit amet quam posuere viverra eu vel tellus.",
            },
          ]}
          imageOnLeft={false}
        />
        <AboutSection
          imgUrl="/images/home_1/about_2.png"
          // spiningImgUrl="/images/home_1/about_mini.svg"
          title="Letting patients go hands-free"
          subTitle="EnquAIre"
          featureList={[
            {
              featureListTitle: "Donec scelerisque ultricies dapibus",
              featureListSubTitle:
                "Duis sit amet diam massa. Fusce tincidunt nisl non hendrerit cursus. Pellentesque et erat sit amet quam posuere viverra eu vel tellus. Quisque venenatis purus tortor, sed ullamcorper lorem iaculis at. Praesent in sagittis lorem. Fusce accumsan tempus justo, nec dictum magna. Cras vehicula maximus eros sit amet facilisis.",
            },
          ]}
        />
      </Section> */}
      {/* End About Section */}
      {/* Start Departments Section */}
      {/* <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section> */}

      {/* End Departments Section */}
      {/* Start Award Section */}
      {/* <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Departments" data={awardData} />
      </Section> */}
      {/* End Award Section */}
      {/* Start Testimonial */}
      {/* <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section> */}
      {/* End Testimonial */}
      {/* Start Banner Section */}
      {/* <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.png"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section> */}
      {/* End Banner Section */}
      {/* Start Blog Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      {/* <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section> */}
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section> */}
      {/* End FAQ Section */}
    </>
  );
}
