import React, { useState } from "react";
import Button from "../Button";
import "./styles.scss";

const DefaultModalOpenBtn = ({ handleShow }) => (
  <Button onClick={handleShow} btnText="Open Modal" />
);

const CustomModal = ({
  title,
  body,
  closeBtnTxt = "Close",
  submitBtnTxt = "Submit",
  CustomOpenBtn,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const modalOpenBtn = CustomOpenBtn ? (
    <CustomOpenBtn onClick={handleShow} />
  ) : (
    <DefaultModalOpenBtn handleShow={handleShow} />
  );

  return (
    <>
      {modalOpenBtn}

      {show && (
        <div className="modal show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal_header">
                {title ? <h4 className="modal-title">{title}</h4> : <div></div>}
                <button type="button" className="close" onClick={handleClose}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">{body}</div>
              <div className="modal-footer">
                <Button
                  onClick={handleClose}
                  btnText={closeBtnTxt}
                  variant="btn_secondary"
                />
                <Button onClick={handleClose} btnText={submitBtnTxt} />
              </div>
            </div>
          </div>
        </div>
      )}

      {show && <div className="modal-backdrop show"></div>}
    </>
  );
};

export default CustomModal;
