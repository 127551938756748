import React from 'react';
import AutoScrollingCarousel from "../../AutoScrollingCarousel";
import Spacing from "../../Spacing";

export default function BrandsSection({ data, sectionTitle }) {
  return (
    <div className="container">
      {sectionTitle && (
        <>
          <p className="mb-0 brand_section_heading">{sectionTitle}</p>
          <Spacing md="40" />
        </>
      )}
      <AutoScrollingCarousel data={data} />
    </div>
  );
}
