import parse from "html-react-parser";
import React from "react";
import DemoRequestModal from "../DemoRequest/DemoRequestModal";

export default function Hero({ title, subTitle, subTitle2, bgUrl }) {
  return (
    <section className="cs_hero cs_style_1">
      <div
        className="cs_hero_wrap cs_bg_filed home_bg"
        style={{ backgroundImage: `url(${bgUrl})` }}
      >
        <div className="container">
          <div className="cs_hero_text">
            <h2 className="cs_hero_title cs_fs_48 cs_fw_600">{parse(title)}</h2>
            <p className="cs_hero_subtitle cs_fs_20 cs_heading_color">
              {parse(subTitle)}
            </p>
            <p class="cs_fs_18">{subTitle2}</p>
          </div>
          <div className="cs_hero_btn_wrap">
            <DemoRequestModal />
          </div>
          {/* <div className="cs_hero_info_wrap cs_shadow_1 cs_white_bg cs_radius_15">
            {infoList.map((item, index) => (
              <div className="cs_hero_info_col" key={index}>
                <div className="cs_hero_info d-flex align-items-center">
                  <div className="cs_hero_info_icon cs_center rounded-circle cs_accent_bg">
                    <img src={item.iconUrl} alt="Icon" />
                  </div>
                  <div className="cs_hero_info_right">
                    <h3 className="cs_hero_info_title cs_semibold">
                      {item.title}
                    </h3>
                    <p className="cs_hero_info_subtitle cs_fs_20">
                      {item.subTitle}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div className="cs_hero_info_col">
              <Link to={btnUrl} className="cs_btn cs_style_1">
                <span>{btnText}</span>
                <i>
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                </i>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
