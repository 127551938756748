import CustomModal from "../../Modal";
import DemoRequestButton from "../DemoRequestButton";
import DemoRequestForm from "../DemoRequestForm";

const DemoRequestModal = () => {
  return (
    <CustomModal
      title="Request a demo"
      body={<DemoRequestForm />}
      CustomOpenBtn={DemoRequestButton}
    />
  );
};

export default DemoRequestModal;
