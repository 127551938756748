import "./styles.scss";

import Slider from "react-infinite-logo-slider";

const AutoScrollingCarousel = ({ data }) => {
  return (
    <Slider
      width="220px"
      duration={25}
      pauseOnHover={true}
      blurBorders
      blurBoderColor={"#fff"}
    >
      {data?.map((item, index) => (
        <Slider.Slide className="brand_logo">
          <img key={index} src={item.imgUrl} alt={item.imgAlt} />
        </Slider.Slide>
      ))}
    </Slider>
  );
};

export default AutoScrollingCarousel;
