import React from "react";
import Spacing from "../../Spacing";

export default function BenefitSection({
  imgUrl,
  title,
  subTitle,
  featureList,
  imageOnLeft = true,
  alignRight = false,
}) {
  return (
    <section className="cs_about cs_style_1">
      <Spacing md="55" />
      <div className="container">
        <div className="row align-items-center flex-lg-row cs_gap_y_40">
          {imageOnLeft && (
            <>
              <div className="col-lg-2"></div>
              <div className="col-lg-4">
                <div className="cs_about_img">
                  <img src={imgUrl} alt="About" />
                </div>
              </div>
            </>
          )}
          {!imageOnLeft && <div className="col-lg-2"></div>}
          <div className="col-lg-4">
            <div className="benefit_content">
              <div className="cs_section_heading cs_style_1">
                <h2
                  className={`cs_section_title cs_fs_32 m-0 ${
                    alignRight ? "right-aligned" : ""
                  }`}
                >
                  {title}
                </h2>
                {/* <div className="cs_height_5" />
                <h3
                  className={`cs_section_subtitle cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_20  ${
                    alignRight ? "right-aligned" : ""
                  }`}
                >
                  {subTitle}
                </h3> */}
              </div>
              {featureList.map((item, index) => (
                <>
                  <h3 className="cs_normal cs_fs_32 cs_body_color">
                    {item.featureListTitle}
                  </h3>
                  <p className={`m-0 ${alignRight ? "right-aligned" : ""}`}>
                    {item.featureListSubTitle}
                  </p>
                </>
              ))}
            </div>
          </div>
          {imageOnLeft && <div className="col-lg-2"></div>}
          {!imageOnLeft && (
            <>
              <div className="col-lg-4">
                <div className="cs_about_img">
                  <img src={imgUrl} alt="About" />
                </div>
              </div>
              <div className="col-lg-2"></div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
