import React from "react";
import { pageTitle } from "../../helpers/PageTitle";
import Section from "../Section";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import TestimonialSection from "../Section/TestimonialSection";

export default function Testimonials() {
  pageTitle("Departments");
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/products_page_bg.png"
        imgUrl="/images/departments/banner_img.png"
        title="Voices of Satisfaction"
        subTitle="Our clients' feedback speaks volumes about our commitment to excellence. Discover their experiences and see how we've made a difference."
      />
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Client Kudos"
          sectionTitleDown="Words of Praise"
        />
      </Section>
    </>
  );
}
