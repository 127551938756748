import BenefitSection from "../Section/BenefitSection";
import SectionHeading from "../SectionHeading";

const Benefits = () => {
  return (
    <>
      <SectionHeading
        titleUp="Taking the Stress Out of Your Healthcare Appointment"
        subTitle="Say goodbye to the hassle of pre-check-in paperwork and electronic forms! Our AI technology takes care of it all, allowing you and your doctor to focus on what truly matters: your health."
        center
        subTitleClassName="benefits-subtitle"
      />
      <section className="benefits-container">
        <BenefitSection
          imgUrl="/images/benefits/benefit_1.jpg"
          title={
            <>
              <span class="cs_accent_color">Serving</span> Outpatient
            </>
          }
          featureList={[
            {
              featureListSubTitle:
                "No need to fill out pre-check-in paperwork or electronic forms! Our AI provides your doctor with personalized information, ensuring you receive the most tailored care without the hassle of form-filling.",
            },
          ]}
        />
        <BenefitSection
          imgUrl="/images/benefits/benefit_22.jpg"
          title={
            <>
              <span class="cs_accent_color">Accompanying</span> Inpatient
            </>
          }
          featureList={[
            {
              featureListSubTitle:
                "Your care comes first! Our AI acts as your companion throughout your hospital stay, staying connected with the healthcare facility to address your concerns and ensure a smooth experience.",
            },
          ]}
          imageOnLeft={false}
          alignRight
        />
        <BenefitSection
          imgUrl="/images/benefits/benefit_3.jpg"
          title={
            <>
              <span class="cs_accent_color">Empowering</span> Doctors
            </>
          }
          featureList={[
            {
              featureListSubTitle:
                "Facilitating medical experts and empowering them to be more effective for you through the power of modern AI.",
            },
          ]}
        />
        {/* <AboutSection
          imgUrl="/images/benefits/benefit_1.jpg"
          // spiningImgUrl="/images/home_1/about_mini.svg"
          title="Saving Doctor's Time"
          subTitle="EnquAIre"
          featureList={[
            {
              featureListTitle: "Lorem ipsum dolor sit amet",
              featureListSubTitle:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae aliquam nulla. Nulla in ullamcorper eros. Aenean sit amet malesuada ligula. In hac habitasse platea dictumst. Suspendisse porta tempus turpis eget dapibus. Quisque pretium varius metus sit amet tincidunt. Donec nec lacus suscipit, bibendum augue auctor, pulvinar quam.",
            },
          ]}
        />
        <AboutSection
          imgUrl="/images/benefits/benefit_2.jpg"
          // spiningImgUrl="/images/home_1/about_mini.svg"
          title="Empowering doctors for accurate diagnosis"
          subTitle="EnquAIre"
          featureList={[
            {
              featureListTitle:
                "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet",
              featureListSubTitle:
                "Maecenas id ex in libero semper pretium. In scelerisque vulputate metus, sit amet commodo neque tincidunt ut. Duis sit amet diam massa. Fusce tincidunt nisl non hendrerit cursus. Pellentesque et erat sit amet quam posuere viverra eu vel tellus.",
            },
          ]}
          imageOnLeft={false}
        />
        <AboutSection
          imgUrl="/images/benefits/benefit_3.jpg"
          // spiningImgUrl="/images/home_1/about_mini.svg"
          title="Letting patients go hands-free"
          subTitle="EnquAIre"
          featureList={[
            {
              featureListTitle: "Donec scelerisque ultricies dapibus",
              featureListSubTitle:
                "Duis sit amet diam massa. Fusce tincidunt nisl non hendrerit cursus. Pellentesque et erat sit amet quam posuere viverra eu vel tellus. Quisque venenatis purus tortor, sed ullamcorper lorem iaculis at. Praesent in sagittis lorem. Fusce accumsan tempus justo, nec dictum magna. Cras vehicula maximus eros sit amet facilisis.",
            },
          ]}
        /> */}
      </section>
    </>
  );
};

export default Benefits;
