import { useForm } from "react-hook-form";

const defaultValues = {
  firstname: "",
  lastname: "",
  institute: "",
  phone: "",
  email: "",
  wayToContact: "",
  timeToContact: "",
};

const DemoRequestForm = () => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm(defaultValues);
  const onSubmit = (data) => console.log(data);

  console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <div className="demo_request_form_cont">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">First Name</label>
          <input
            className="cs_form_field"
            {...register("firstname", { required: true })}
            placeholder="Enter your first name"
          />
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">Last Name</label>
          <input
            className="cs_form_field"
            {...register("lastname", { required: true })}
            placeholder="Enter your last name"
          />
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Institute Name
          </label>
          <input
            className="cs_form_field"
            {...register("institute", { required: true })}
            placeholder="Enter your institute name"
          />
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Best way to contact you
          </label>
          <select
            className="cs_form_field"
            {...register("wayToContact", { required: true })}
          >
            <option value="email">Email</option>
            <option value="phone">Phone</option>
            <option value="text">Text Message</option>
          </select>
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">Phone</label>
          <input
            className="cs_form_field"
            {...register("phone")}
            placeholder="Enter your phone number e.g. xxx-xxx-xxxx"
          />
        </div>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            className="cs_form_field"
            {...register("email")}
            placeholder="Enter your email e.g. xyz@abc.com"
          />
        </div>

        {/* errors will return when field validation fails  */}
        {errors.exampleRequired && <span>This field is required</span>}

        {/* <input type="submit" /> */}
      </form>
    </div>
  );
};
export default DemoRequestForm;
