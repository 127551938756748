import React from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import Section from "../Section";
import DepartmentSectionStyle3 from "../Section/DepartmentSection/DepartmentSectionStyle3";
import BannerSectionStyle6 from "../Section/BannerSection/BannerSectionStyle6";
import { pageTitle } from "../../helpers/PageTitle";
const departmentData = [
  {
    iconUrl: "images/departments/products_icon.svg",
    title: "Empathayz",
    subTitle:
      "A voice-based pre-checkin allows patients to complete the check-in process using voice commands, streamlining and simplifying their experience at medical facilities.",
    href: "/departments/department-details",
  },
  {
    iconUrl: "images/departments/products_icon.svg",
    title: "WisDoc",
    subTitle:
      "Assists doctors by providing evidence-based recommendations and insights, helping to enhance patient care, improve decision-making accuracy, and optimize treatment outcomes.",
    href: "/departments/department-details",
  },
  {
    iconUrl: "images/departments/products_icon.svg",
    title: "Solara",
    subTitle:
      "Supports hospital staff by assisting with patient care tasks, monitoring patient conditions, and ensuring a comfortable stay for patients.",
    href: "/departments/department-details",
  },
  {
    iconUrl: "images/departments/products_icon.svg",
    title: "CareLog",
    subTitle:
      "A post-visit health management system keeps track of patients' health information, medications, and follow-up appointments to ensure ongoing care and proper scheduling.",
    href: "/departments/department-details",
  },
];

export default function Departments() {
  pageTitle("Departments");
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/products_page_bg.png"
        imgUrl="/images/departments/banner_img.png"
        title="Get to Know <br>EnquAIre Products"
        subTitle="At EnquAIre, we offer a wide range of medical and healthcare services that are designed to meet your individual needs and help you achieve optimal health."
      />
      <Section bottomMd={140} bottomLg={100} bottomXl={60}>
        <DepartmentSectionStyle3 data={departmentData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle6
          imgUrl="/images/departments/banner_img_2.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our <br />experienced medical professionals today!"
        />
      </Section>
    </>
  );
}
